.d-tree-container {
    list-style: none;
    padding: 0;
  }
  
  .d-tree-node {
    padding: 0.10rem 0.5rem;
  }
  
  .d-tree-toggler.active {
    transform: rotate(45deg);
  }