.custom-table {
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 12px;
    border-color: #67b831 !important;
  }
  
  /* .custom-table td,
  .custom-table th {
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 12px;
  } */
  
  .custom-table thead th {
  
    color: white;
  }
  .custom-table thead tr {
    background-color: #188BAD !important;
    color: white;
  }
  
  .custom-table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }