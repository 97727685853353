.react-autosuggest__input {
  display: block;
  width: 100%;
  font-weight: 400;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.autocomplete-input {
  font-size: 14px;
  padding: 8px;
  height: 36px;
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;
}

.button-container2 {
  display: flex;
  justify-content: space-evenly;
  margin: 3rem 0;
}

.report-button {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  height: 40px;
  position: relative;
  width: calc(16% - 3rem);
  overflow: hidden;
  color: white;
  display: block;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
  top: 50%;
  transform: translateY(-50%);
  /* evitar que el contenido se salga del botón */
}

.movie-button {
  background-color: #333;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  /* transition: all 0.2s ease-in-out; */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  top: 0;
  left: 0;

}

.movie-button:hover {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.3);
  transform: scale(1.2);
}

.movie-button-text {
  color: white;
  display: block;
  position: absolute;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
  top: 50%;
  transform: translateY(-50%);
}

.inputmov {
  width: 35% !important;
  height: 100% !important;
  font-size: 80% !important;
  background-color: #fff !important;
}

.inputmovgrilla {
  /* width: 100% !important; */
  /* max-width: fit-content; */
  /* height: 70% !important; */
  font-size: 80% !important;
  background-color: #fff !important;
}

.desabilitar {
  pointer-events: none;
}
.nodesabilitar {
  pointer-events: all;
}
.chattext {
  width: fit-content;
  display: inline-block;
  flex-wrap: wrap;
}

.labelmov {
  width: 35% !important;
  height: 100% !important;
  font-size: 80% !important;
  background-color: #188BAD !important;
  color: white;
}

.negritagrande {
  color: 'black' !important;
  text-align: 'center' !important;
  font-weight: 'bold' !important
}

.inputmovtotal {
  width: 35% !important;
  height: 90% !important;
  font-size: 100% !important;
  background-color: #fff !important;
  /* background-color: #ffa1eb!important; */
}

.labelmovtotal {
  width: 35% !important;
  height: 90% !important;
  font-size: 100% !important;
  background-color: #065066 !important;
  color: white;
}

.labelmovtotalmediopago {
  width: 35% !important;
  height: 90% !important;
  font-size: 100% !important;
  background-color: #188BAD !important;
  color: white;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  background-color: #fff;
  border: 1px solid #aaa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 96%;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.bordetabla {
  border-color: black !important;
  border-bottom: 3px solid;
}

.cardsinsidecurrent {
  margin-right: 1%;
  width: 10rem;
  cursor: pointer;
}

.cardsinsidecurrent:hover {
  background: #188BAD;
}


/* I added this additional code */
.flexbetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
}

.flexarround {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
}

.flexevenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row wrap;
}

.flexbetweenwithnocenter {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.displayflex {
  display: flex;
}

.defaultButtonColor {
  background: #f36f28;
  border: none;
  border-radius: 40px;
}

.hamburger {
  font-size: 20px;
}

.appname span {
  font-weight: 700;
}

.cart-icon {
  font-size: 25px;
}

::-webkit-scrollbar-thumb {
  background: #343a40;
}

.scrollbuttons {
  position: fixed;
  bottom: 20px;
  right: 30px;
}

.scrollbuttons a i {
  font-size: 40px;
  color: #343a40;
}

.cardsinsidetables {
  width: 18rem;
}

.cardsinsidemenus {
  width: 18rem;
}

.cardsinsidedashboard {
  width: 15rem;
}

.menucardtitlediv {
  height: 60px;
  display: block;
  overflow: hidden;
}

.cardsinsidecurrent {
  margin-right: 1%;
  width: 10rem;
  cursor: pointer;
}

.cardsinsidecurrent:hover {
  background: #ededed;
}

.currentcardlist {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}

.classheaderspan a {
  float: right;
  cursor: pointer;
}

.dashboardcards {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: row wrap;
}

.ordercard {
  width: 15rem;
}

.ordercardfornonadmin {
  width: 100%;
}

.reservedColor {
  background-color: #d53343;
  color: white;
}

.not-allowed.disabled {
  cursor: not-allowed !important;
}

.downloadprinticons i {
  font-size: 15px;
}

.none {
  display: none;
}


.authlabelandimg {
  display: flex;
  justify-content: space-around !important;
  align-items: center;
  flex-flow: row wrap;
  min-height: 100vh;
}

.title1 {
  font-size: 4em;
  overflow: hidden;
}

.title2 {
  font-size: 2em;
  overflow: hidden;
}

.authimg {
  width: 600px;
  height: 350px;
}

.authContainer {
  margin: 5% auto;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  width: 500px;
  max-width: 100%;
}

.authForm {
  padding: 10%;
}

.error-notice {
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #e07c7c;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8d6d6;
}

.error-notice button {
  border: none;
  background: none;
  width: 20px;
  height: 20px;
}

.transparentbutton {
  border: none;
  background: none;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

#lblCartCount {
  font-size: 15px;
  background: #007bff;
  color: #fff;
  padding: 2px 5px;
  vertical-align: top;
  margin-top: 10px;
}

.menuimgdiv {
  height: 100px;
  width: 100%;
}

.menuImage {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

.chartcard {
  width: 30rem;
}

/*   
  @media print {
    h3,
    hr,
    .downloadprinticons,
    .note,
    .tothetop,
    .sidebar {
      display: none;
    }
  }
   */
/* @media (max-width: 768px) {
    .sidebar {
      min-width: 375px;
      max-width: 375px;
      margin-left: -375px;
    }
    .sidebar.toggled {
      margin-left: 0;
    }
    .content {
      overflow: hidden;
    }
    .appname span {
      font-size: 3.5vw;
    }
  
    .authlabelandimg {
      min-height: 90vh;
    }
    .authimg {
      width: 350px;
      height: 180px;
    }
    .authlabel {
      text-align: center !important;
    }
  
    .title1 {
      font-size: 2.8em;
      text-align: center !important;
      overflow: hidden;
    }
    .title2 {
      font-size: 1.5em;
  
      text-align: center !important;
      overflow: hidden;
    }
  
    .dropdown span {
      display: none;
    }
    .scrollbuttons {
      position: fixed;
      bottom: 5px;
      right: 15px;
    }
  
    .scrollbuttons a i {
      font-size: 30px;
      color: #343a40;
    }
  
    ::-webkit-scrollbar {
      display: none;
    }
    .fontsizefortables {
      font-size: 3vw;
      align-items: center;
    }
    .hideUserName {
      display: none;
    }
  
    .itemcarttablebutton span {
      font-size: 2vw;
    }
  
    .cardsinsidecurrent {
      width: 15rem;
    }
    .menuimgdiv {
      height: 150px;
      width: 100%;
    }
  
    .thead {
      display: none;
    }
    .tbody,
    .tr,
    .td {
      display: block;
      width: 100%;
    }
    .tr {
      margin-bottom: 35px;
    }
    .td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
    .td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
    .cart-icon {
      font-size: 20px;
    }
  }
     */
body {
  overflow-x: scroll;
  /* Barra de desplazamiento horizontal */
  overflow-y: scroll;
}